<template lang="pug">
  v-container
    base-navigation-tabs(
      :items="links"
    )
    v-card.mt-3(
      min-width="70vw"
      min-height="80vh"
      elevation="0"
    )
      router-view
</template>
<script>
export default {
  name: 'Default',
  data () {
    return {
      links: [
        {
          label: 'Orders',
          path: { name: 'orders.table' },
        },
        {
          label: 'New Order',
          path: { name: 'orders.create' },
        },
        {
          label: 'Manage Status',
          path: { name: 'orders.statuses' },
        },
        {
          label: 'Manage Detail Status',
          path: { name: 'orders.detail-statuses' },
        },
        {
          label: 'Cust Orders',
          path: { name: 'orders.customers' },
        },
        {
          label: 'Billing',
          path: { name: 'orders.billings.table' },
        },
        {
          label: 'Reports',
          path: { name: 'orders.reports' },
        },
        // {
        //   label: 'Manage Tax Status',
        //   path: { name: 'orders.tax-statuses' },
        // },
      ],
    }
  },
}
</script>